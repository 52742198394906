@media (min-width: 768px) {
  .btn-group button {
      flex-basis: calc(33.33% - 10px); 
  }
}

@media (min-width: 1024px) {
  .btn-group button {
      flex-basis: calc(25% - 10px); 
  }
}